<template>
  <div class="grid-view">
    <div>
      <div class="bg-white">
        <ImageReview
          :nid="page?.rels?.nid"
          :content-type="page.typename"
          :download-nick="nick"
          :software-title="soft"
          :alt="alt || 'review'"
        />
      </div>
      <v-divider></v-divider>
      <Rating v-model="popularity"></Rating>
      <span class="text-reputation font-weight-bold text-body-2">
        {{ popularity >= 3 ? "Excellent reputation" : "" }}
      </span>
      <div v-if="welcomeBonus" class="bonusClass mb-1 bg-reviewBonusBg rounded-lg pa-6">
        <v-row>
          <v-col cols="3">
            <v-icon icon="$gift" color="redColor" size="x-large" class="ma-auto text-center pa-0 ma-0"></v-icon>
          </v-col>
          <v-col cols="9" class="font-weight-bold">
            <span class="text-caption text-grey-4">Welcome Bonus</span><br />
            {{ welcomeBonus }}
          </v-col>
        </v-row>
      </div>
      <Button
        :download-nick="nick"
        :software-title="soft"
        :text="page.downloadTitle"
        class="w-100 text-uppercase font-weight-bold"
      />
    </div>
    <div>
      <div class="bg-white pl-2 pb-2">
        <div class="pt-2 pb-3 text-capitalize font-weight-bold">More info</div>
        <v-divider v-if="currencies" class="pb-2"></v-divider>
        <div v-if="currencies" class="grid-view">
          <span cols="3">Currencies</span>
          <span cols="9"><see-more :arr="currencies" /></span>
        </div>
        <v-divider v-if="paymentMethods" class="pb-2"></v-divider>
        <div v-if="paymentMethods" class="grid-view">
          <span cols="3">Payment Methods</span>
          <span cols="9"><see-more :arr="paymentMethods" /></span>
        </div>
        <v-divider v-if="gameTypes" class="pb-2"></v-divider>
        <div v-if="gameTypes" class="grid-view">
          <span cols="3">Types</span>
          <span cols="9"><see-more :arr="gameTypes" /></span>
        </div>
        <v-divider v-if="gameThemes" class="pb-2"></v-divider>
        <div v-if="gameThemes" class="grid-view">
          <span cols="3">Themes</span>
          <span cols="9"><see-more :arr="gameThemes" /></span>
        </div>
      </div>
      <div v-if="supportOptions.length > 0" class="bg-white pl-2">
        <div class="mt-2 pt-2 pb-3 text-capitalize font-weight-bold">Support</div>
        <v-divider class="pb-2"></v-divider>
        <div>
          <span v-for="option in supportOptions" :key="option.name" class="px-2">
            <v-icon :icon="option.icon" :color="option.color" size="large"></v-icon>
            {{ option.name }}
          </span>
        </div>
        <v-divider class="pb-2"></v-divider>
      </div>
    </div>
  </div>
</template>
<script setup>
const page = inject("page");
const rels = unref(page).rels;
const nick = rels?.downloadNick || "";
const soft = rels?.softwareTitle || "";
const welcomeBonus = _.get(rels, ["Welcome Bonus", "title"], "");
const currencies = rels?.Currencies || "";
const paymentMethods = rels?.["Payment Methods"] || "";
const gameTypes = rels?.Type || "";
const gameThemes = rels?.Themes || "";
const alt = rels?.Title;
const popularity = rels?.Popularity;
const supportOptions = computed(() => {
  const tmp = [];
  _.mapKeys(_.get(rels, ["Support options"], {}), function (value, key) {
    tmp.push({ name: _.capitalize(key), color: value ? "#26c280" : "#ff3344", icon: value ? "$check" : "$close" });
  });
  return tmp;
});
</script>
